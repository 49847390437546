<template lang="pug">
  #identification-introduction
    icon.card-image(data="@icon/card-image.svg")
    h1 Now, let's take a picture of your driver's license or passport
    button.continue Continue to ID
    button.why Why do we need this?
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
