import Vue from 'vue'

const AmplitudeAPI = {
    logEvent: (eventName, params) => {
        try {
            Vue.prototype.$amplitude.getInstance().logEvent(eventName, params)
        }
        catch(e) {
            console.log(e);
        }
    }
}

export default AmplitudeAPI