import Vue from 'vue'

export const state = {
  localVideoTrack: null,
  localAudioTrack: null,
  localDataTrack: null,
}

export const getters = {}

export const mutations = {
  SET_LOCAL_VIDEO_TRACK(state, track) {
    state.localVideoTrack = track
  },
  SET_LOCAL_AUDIO_TRACK(state, track) {
    state.localAudioTrack = track
  },
  SET_LOCAL_DATA_TRACK(state, track) {
    state.localDataTrack = track
  },
}

export const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
