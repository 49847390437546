<template lang="pug">
  #step-frame
    .step-instructions [ instructions ]
    .twilio-call [ twilio ]
    .step-navigation [ step navigation ]
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
