import Vue from 'vue'
import store from '@/store'

const app = new Vue({
  store,
  computed: {
    showCallConnecting: {
      get: function () {
        return this.$store.state.ui.showCallConnecting
      },
      set: function (value) {
        this.$store.commit('ui/SET_SHOW_CALL_CONNECTING', value)
      },
    },
    callTimestamp: {
      get: function () {
        return this.$store.state.ui.callTimestamp
      },
      set: function (value) {
        this.$store.commit('ui/SET_CALL_TIMESTAMP', value)
      },
    },
    callDate: {
      get: function () {
        return this.$store.state.ui.callDate
      },
      set: function (value) {
        this.$store.commit('ui/SET_CALL_DATE', value)
      },
    },
    forceLandscape: {
      get: function () {
        return this.$store.state.ui.forceLandscape
      },
      set: function (value) {
        this.$store.commit('ui/SET_FORCE_LANDSCAPE', value)
      },
    },
  },

  methods: {},
})

export default {
  install: function (Vue) {
    Vue.app = app
    Vue.prototype.$app = app
  },
}
