<template lang="pug">
  .app-modal-wrapper
    .app-modal(v-for="(modal, index) in modals" :key="modal.componentPath")
      .app-modal-bg(@click="close(modal)")
      keep-alive
        component.modal-content(
          :is="loadedComponents[index]"
          v-bind="modal.props"
        )
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {},

  data() {
    return {}
  },

  computed: {
    ...mapState({
      modals: state => state.modal.modals,
    }),
    loadedComponents,
  },

  methods: {
    close,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function loadedComponents() {
  return this.modals.map(modal => {
    return () => import(`@/components/modals/${modal.componentPath}`)
  })
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function close(modal) {
  if (!modal.props.preventBackgroundTap) {
    this.$modal.closeAll()
  }
}
</script>
