<template lang="pug">
#app-layout
  template(v-if="showRotateInstructions")
    #rotate
      icon(data="@icon/phone-rotate-portrait.svg")
      span Please rotate your device.
  template(v-else)
    #close(v-if="this.$route.meta.webViewContainer", @click="exit")
      icon(data="@icon/close.svg")
    component(:is="(this.$route.meta.layout || 'Default') + 'Layout'")
      RouterView(:key="$route.fullPath")
</template>

<script>
const UAParser = require('ua-parser-js')

export default {
  props: {},

  data() {
    return {
      window: {},
    }
  },

  computed: {
    showRotateInstructions,
  },

  watch: {},

  mounted() {
    this.$breakpoint.build()
  },

  methods: {
    exit,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function showRotateInstructions() {
  const userAgent = new UAParser()
  const isIPhone = /iphone/i.test(userAgent.getDevice().model)
  const isAndroid = /android/i.test(userAgent.getOS().name)
  return (isIPhone || isAndroid) && this.$breakpoint.orientation == 'landscape'
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */

function exit() {
  ReactNativeWebView.postMessage('user_exited')
}
</script>
