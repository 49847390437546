const data = {
  title: 'Entry Steps',
  sections: [
    {
      title: 'Welcome',
      steps: [
        {
          component: 'StartKit',
          config: {},
        },
      ],
    },
    {
      title: 'Kit Verification',
      steps: [
        {
          component: 'VerifyBoxStart',
          config: {},
        },
        {
          component: 'VerifyBox',
          config: {},
        },
        {
          component: 'VerifySuccess',
          config: {},
        },
      ],
    },
  ],
}

export default data
