import stepsEntry from '@/data/entry'
import stepsLegacyEntry from '@/data/legacy-entry'
import stepsIntrivo from '@/data/intrivo'
import stepsDataDEV from '@/data/dev'
import stepsDataUT from '@/data/user-test'

const stepsMap = {
  stepsEntry: stepsEntry,
  stepsLegacyEntry: stepsLegacyEntry,
  stepsIntrivo: stepsIntrivo,
  stepBuilderDEV: stepsDataDEV,
  stepBuilderUT: stepsDataUT,
}

export default stepsMap
