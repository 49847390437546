<template lang="pug">
  #default-layout
    template(v-if="loaded")
      RouterView(:key="$route.fullPath")
    AppModal(v-if="$modal.visible")
</template>

<script>
export default {
  props: {},

  data() {
    return {
      loaded: true,
    }
  },

  computed: {
    showMobileFooter,
  },

  watch: {
    $route(to, from) {
      this.$el.scrollTop = 0
    },
  },

  methods: {},

  created() {
    // this.$auth.load().then(() => {
    //   this.$app.user = this.$auth.user()
    //   this.$cable.connection.connect(
    //     `${process.env.VUE_APP_API_URL}/realtime?token=${this.$auth.user().accessToken}`
    //   )
    //   this.loaded = true
    // })
  },

  mounted() {},

  components: {
    AppModal: require('@/components/AppModal').default,
  },
}

/* Computed ---------------------------------------------------- */
function showMobileFooter() {
  return this.$breakpoint.isTouch && this.$route.name != 'userSettings'
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
