export default {
  install(Vue) {
    Vue.directive('scrolled-to-end', {
      bind(el, binding, vnode) {
        el.onscroll = function() {
          let next = binding.value()

          if (next && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            binding.value(true)
          }
        }
      },
    })
  },
}
