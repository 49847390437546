class CacheApi {
  static instance
  constructor() {
    this.cacheName = 'video-cache'
    if (CacheApi.instance) {
      return CacheApi.instance
    }
    CacheApi.instance = this
  }

  async addData(url) {
    const cacheStorage = await caches.open(this.cacheName)
    try {
      return await cacheStorage.add(url)
    } catch (error) {
      throw error
    }
  }

  async getData(url) {
    let cachedData = await this.getCachedData(url)

    if (cachedData) {
      return cachedData
    }

    const cacheStorage = await caches.open(this.cacheName)
    await cacheStorage.add(url)
    cachedData = await this.getCachedData(url)
    await this.deleteOldCaches(this.cacheName)

    return cachedData
  }

  async getCachedData(url) {
    const cacheStorage = await caches.open(this.cacheName)
    const cachedResponse = await cacheStorage.match(url)

    if (!cachedResponse || !cachedResponse.ok) {
      return false
    }

    return await cachedResponse.arrayBuffer()
  }

  async fetchVideo(url) {
    const arrayBuffer = await this.getData(url)
    const video = new Blob([new Uint8Array(arrayBuffer)], { type: 'video/mp4' })
    return URL.createObjectURL(video)
  }

  async clearVideoCache() {
    caches.keys().then(keyList => {
      return Promise.all(
        keyList.map(key => {
          return caches.delete(key)
        })
      )
    })
  }

  // Delete any old caches to respect user's disk space.
  async deleteOldCaches(currentCache) {
    const keys = await caches.keys()

    for (const key of keys) {
      const isOurCache = 'myapp-' === key.substr(0, 6)

      if (currentCache === key || !isOurCache) {
        continue
      }

      caches.delete(key)
    }
  }
}

export { CacheApi as default }
