import Vue from 'vue'

// Layouts --------------------------------------------------
import DefaultLayout from '@/layouts/DefaultLayout.vue'
Vue.component('DefaultLayout', DefaultLayout)

import ErrorsLayout from '@/layouts/ErrorsLayout.vue'
Vue.component('ErrorsLayout', ErrorsLayout)

import SessionsLayout from '@/layouts/SessionsLayout.vue'
Vue.component('SessionsLayout', SessionsLayout)

import InfoLayout from '@/layouts/InfoLayout.vue'
Vue.component('InfoLayout', InfoLayout)

const components = {
  AllAgentsBusy: () => import('@/steps/AllAgentsBusy'),
  CapturePhoto: () => import('@/steps/CapturePhoto'),
  ConnectingToAgent: () => import('@/steps/ConnectingToAgent'),
  EnablePermissions: () => import('@/steps/EnablePermissions'),
  NeedLicensePhoto: () => import('@/steps/NeedLicensePhoto'),
  NeedSignature: () => import('@/steps/NeedSignature'),
  NeedSignatureBDO: () => import('@/steps/NeedSignatureBDO'),
  PackingUpChecklist: () => import('@/steps/PackingUpChecklist'),
  PostCallThanks: () => import('@/steps/PostCallThanks'),
  ResumeVideo: () => import('@/steps/ResumeVideo'),
  SignatureCapture: () => import('@/steps/SignatureCapture'),
  StartKit: () => import('@/steps/StartKit'),
  StartKitChecklist: () => import('@/steps/StartKitChecklist'),
  StaticPageTemplate: () => import('@/steps/StaticPageTemplate'),
  TermsOfService: () => import('@/steps/TermsOfService'),
  TermsOfServiceBDO: () => import('@/steps/TermsOfServiceBDO'),
  ThankYou: () => import('@/steps/ThankYou'),
  UserInfo: () => import('@/steps/UserInfo'),
  UserInfoBDO: () => import('@/steps/UserInfoBDO'),
  VerifyBox: () => import('@/steps/VerifyBox'),
  VerifyLegacyBox: () => import('@/steps/VerifyLegacyBox'),
  VerifyBoxStart: () => import('@/steps/VerifyBoxStart'),
  VerifySuccess: () => import('@/steps/VerifySuccess'),
  VideoGate: () => import('@/steps/VideoGate'),
  VideoStage: () => import('@/steps/VideoStage'),
  VolumeCheck: () => import('@/steps/VolumeCheck'),
  WelcomeChecklist: () => import('@/steps/WelcomeChecklist'),
  HIVConsent: () => import('@/steps/HIVConsent'),
  Docusign: () => import('@/steps/Docusign'),
  DayOneCompletion: () => import('@/steps/DayOneCompletion'),

  // Photo Overlays
  BloodPressureWithSelfie: () => import('@/components/overlays/BloodPressureWithSelfie'),
  BloodPressureWithSelfieVideo: () => import('@/components/overlays/BloodPressureWithSelfieVideo'),
  BloodSampleWithSelfie: () => import('@/components/overlays/BloodSampleWithSelfie'),
  CentrifugeWithSelfieVideo: () => import('@/components/overlays/CentrifugeWithSelfieVideo'),
  SalivaWithSelfie: () => import('@/components/overlays/SalivaWithSelfie'),
  SalivaSelfieVideo: () => import('@/components/overlays/SalivaSelfieVideo'),
  SelfieTopHalf: () => import('@/components/overlays/SelfieTopHalf'),
  WeightScale: () => import('@/components/overlays/WeightScale'),
  WeightScaleWithSelfie: () => import('@/components/overlays/WeightScaleWithSelfie'),
  BloodSample: () => import('@/components/overlays/BloodSample'),

  UserTestingThanks: () => import('@/steps/UserTestingThanks'),
}

Object.entries(components).forEach(([name, component]) => Vue.component(name, component))
