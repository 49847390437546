import Vue from 'vue'
import VueRouter from 'vue-router'
import defaultRoutes from '@/router/defaultRoutes'
import errorRoutes from '@/router/errorRoutes'
import sessionRoutes from '@/router/sessionRoutes'

Vue.use(VueRouter)

// const routes = [...sessionRoutes, ...defaultRoutes]
const routes = [...defaultRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
