import Vue from 'vue'
import amplitude from 'amplitude-js'

amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY, null,  {
    trackingOptions: {
      city: false,
      ip_address: false
    }
}); // initializes default instance of Amplitude client

Vue.prototype.$amplitude = amplitude