<template lang="pug">
div
  transition(name="slide", mode="out-in")
    component(
      :is="currentStep.component",
      :key="currentStep.stepPos",
      v-bind="{ ...currentStep.config }",
      @complete="complete",
      @append="append"
    )
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import stepsMap from '@/data/steps-map'
import StepBuilder from '@/utils/stepBuilder'
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'

export default {
  props: {},

  data() {
    return {
      stepBuilder: null,
      initialTime: Date.now(),
    }
  },

  computed: {
    ...mapState({
      currentSection: state => state.steps.currentSection,
      currentStep: state => state.steps.currentStep,
    }),
    ...mapGetters({
      barcodeId: 'user/getBarcodeId',
      kitType: 'user/getKitType',
      medplumId: 'user/getMedplumOrderId',
      configKeys: 'user/getConfigKeys',
    }),
  },

  watch: {},

  async created() {
    this.stepBuilder = new StepBuilder()
    await this.stepBuilder.build(stepsMap[this.$route.name].sections)
    await this.append()
    this.$amplitude.getInstance().setUserId(this.barcodeId)
    this.$mixpanel.identify(this.barcodeId)
  },

  methods: {
    complete,
    append,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function complete(bypassAmplitude = false) {
  if (!bypassAmplitude) {
    const eventProperties = {
      kit_type: this.kitType,
      medplum_id: this.medplumId,
      barcode: this.barcodeId,
      sku: this.$store.getters['user/getSku'],
      customer: this.configKeys ? this.configKeys.carrier : null,
      segment_time: Date.now() - this.initialTime,
      source: this.$route.query.src,
      device: this.$breakpoint.isPhone ? 'Mobile' : 'Desktop',
    }

    AmplitudeAPI.logEvent(this.currentStep.component, {
      ...eventProperties,
      linked_amplitude_id: this.$route.query.q,
    })

    MixpanelAPI.track(this.currentStep.component, {
      ...eventProperties,
      linked_mixpanel_id: this.$route.query.q,
      application: 'Collection App',
    })
  }

  this.$store.dispatch('steps/nextStep')
  this.initialTime = Date.now()
  window.scrollTo(0, 0)
}

async function append() {
  var sectionUrls = this.$store.getters['user/getFlowSections']

  if (Array.isArray(sectionUrls)) {
    const res = await Promise.all(
      sectionUrls.map(url => fetch(url, { method: 'GET', mode: 'cors' }).then(res => res.json()))
    )

    this.stepBuilder.appendSections(res.flat(1))
  }
}
</script>
