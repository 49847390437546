import Vue from 'vue'
import Bugsnag from '@bugsnag/js'

export const state = {
  currentSection: {},
  currentStep: {},
  sections: [],
  steps: [],
  mediaLoaded: false,
  mediaLoadedPercent: 0,
}

export const getters = {
  findSectionByPos: state => index => state.sections[index],
  findStepByPos: state => index => state.currentSection.steps[index],
}

export const mutations = {
  SET_CURRENT_SECTION(state, section) {
    if (section) {
      try {
        var jsonSection = JSON.stringify(section)
        localStorage.setItem('currentSection', jsonSection)
        state.currentSection = section
      } catch (error) {
        Bugsnag.notify(error)
      }
    }
  },

  SET_CURRENT_STEP(state, step) {
    if (step) {
      try {
        var jsonStep = JSON.stringify(step)
        localStorage.setItem('currentStep', jsonStep)
        state.currentStep = step
      } catch (error) {
        Bugsnag.notify(error)
      }
    }
  },

  SET_SECTIONS(state, section) {
    state.sections.push(section)
  },

  SET_MEDIA_LOADED(state, boolean) {
    state.mediaLoaded = boolean
  },

  SET_MEDIA_LOADED_PERCENT(state, percent) {
    state.mediaLoadedPercent = percent
  },

  UPDATE_SECTION(state, data) {
    // data =  { sectionPos: <Number>, fields: {} }
    const section = state.sections[data.sectionPos]

    if (section) {
      const updatedSection = Object.assign(section, data.fields)

      if (data.sectionPos == state.currentSection.indexPos) {
        // update the current section, including value in localstorage
        this.commit('steps/SET_CURRENT_SECTION', updatedSection)
      }
    }
  },
}

export const actions = {
  async create({ commit }, data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })
  },

  async nextStep({ commit, getters }, data) {
    const currentSection = state.currentSection
    const currentStep = state.currentStep

    if (currentStep.nextStepPos == -1) {
      const nextSection = getters.findSectionByPos(currentSection.nextSectionPos)
      commit('SET_CURRENT_SECTION', nextSection)
      commit('SET_CURRENT_STEP', nextSection.steps[0])
    } else {
      const nextStep = getters.findStepByPos(currentStep.nextStepPos)
      commit('SET_CURRENT_STEP', nextStep)
    }
  },

  async updateSection({ commit, state }, data) {
    commit('UPDATE_SECTION', data)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
