import Vue from 'vue'

export const state = {
  showCallConnecting: false,
  callTimestamp: null,
  callDate: null,
  forceLandscape: false
}

export const getters = {}

export const mutations = {
  SET_SHOW_CALL_CONNECTING(state, value) {
    state.showCallConnecting = value
  },

  SET_CALL_TIMESTAMP(state, value) {
    state.callTimestamp = value
  },

  SET_CALL_DATE(state, value) {
    state.callDate = value
  },

  SET_FORCE_LANDSCAPE(state, value) {
    state.forceLandscape = value
  }
}

export const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
