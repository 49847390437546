import Vue from 'vue'

import VueMeta from 'vue-meta'
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

import { VueSvgIcon } from '@yzfe/vue-svgicon'
Vue.component('icon', VueSvgIcon)

import VueMask from 'v-mask'
Vue.use(VueMask)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import app from '@/plugins/app'
Vue.use(app)
