// section 0, section 1, section 2

const data = {
  title: 'Finger Prick Only Flow',
  sections: [
    {
      title: 'Welcome',
      steps: [
        {
          component: 'StartKit',
          config: {},
        },
      ],
    },
    {
      title: 'Kit Verification',
      steps: [
        {
          component: 'VerifyBoxStart',
          config: {},
        },
        {
          component: 'VerifyLegacyBox',
          config: {},
        },
        {
          component: 'VerifySuccess',
          config: {},
        },
      ],
    },
    {
      title: 'Permissions',
      steps: [
        {
          component: 'EnablePermissions',
          config: {},
        },
      ],
    },
    // {
    //   title: 'Video Gate',
    //   steps: [
    //     {
    //       component: 'VideoGate',
    //       config: { percentGated: 50 },
    //     },
    //   ],
    // },
    {
      title: 'Videos',
      steps: [
        {
          component: 'VideoStage',
          config: {
            data: [
              {
                coachMark: true,
                title: 'coachMark',
                prompt: 'coachMark',
                step: null,
                videoFile: '/segments/mp4s/coachmarks.mp4',
                duration: 18,
                actionButton: {
                  type: 'done',
                },
                instructions: [],
              },
              {
                title: 'Setup',
                prompt: 'Setup',
                step: null,
                videoFile: '/segments/mp4s/ver-11162020/0.mp4',
                duration: 15,
                actionButton: {
                  type: 'done',
                },
                instructions: [
                  {
                    text: 'Take phone stand from red sheet',
                    image: '/segments/step-pics/setup_1_1.png',
                  },
                  {
                    text: 'Open phone stand to tallest position',
                    image: '/segments/step-pics/setup_1_2.png',
                  },
                  {
                    text: 'Place phone on stand ~ 1 foot away, so app can see both you and table',
                    image: '/segments/step-pics/setup_1_3.png',
                  },
                ],
                capturePhoto: {
                  filenamePrefix: 'Weight_Scale',
                  overlay: 'WeightScale',
                  facing: 'environment',
                  title: 'Can you clearly read the weight scale reading?',
                },
              },
            ],
          },
        },
      ],
    },
    {
      title: 'Thank You',
      steps: [
        {
          component: 'ThankYou',
          config: {
            dropLocation: 'post office',
          },
        },
      ],
    },
  ],
}

export default data