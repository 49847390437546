import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'

Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/app`
Vue.axios.defaults.headers.common['Accept'] = 'application/json'

Vue.axios.interceptors.request.use(
  function(config) {
    const authToken = localStorage.getItem('auth_token')
    const accessToken = localStorage.getItem('access_token')

    // config.headers.Bident = localStorage.getItem('bident')

    if (authToken != null) {
      config.headers.AuthType = 'auth_token'
      config.headers.Authorization = `Token token=${authToken}`
    } else if (accessToken != null) {
      config.headers.AuthType = 'bearer'
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  function(err) {
    return Promise.reject(err)
  }
)

Vue.axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { data, status } = error.response

    if (status === 401 && ['Unauthorized auth'].indexOf(data.error) > -1) {
      window.location = '/logout'
    }

    return Promise.reject(error)
  }
)
