export default [
  {
    path: '/fp',
    name: 'stepBuilderFP',
    meta: { layout: 'default', auth: false },
    component: require('@/views/steps/StepBuilder').default,
  },
  {
    path: '/dev',
    name: 'stepBuilderDEV',
    meta: { layout: 'default', auth: false },
    component: require('@/views/steps/StepBuilder').default,
  },
  {
    path: '/user-test',
    name: 'stepBuilderUT',
    meta: { layout: 'default', auth: false },
    component: require('@/views/steps/StepBuilder').default,
  },
  {
    path: '/entry',
    name: 'stepsEntry',
    meta: { layout: 'default', auth: false },
    component: require('@/views/steps/StepBuilder').default,
  },
  {
    path: '/lge',
    name: 'stepsLegacyEntry',
    meta: { layout: 'default', auth: false },
    component: require('@/views/steps/StepBuilder').default,
  },
  {
    path: '/intrivo',
    name: 'stepsIntrivo',
    meta: { layout: 'default', auth: false, webViewContainer: 'On/Go app' },
    component: require('@/views/steps/StepBuilder').default,
  },
  {
    path: '/identification-introduction',
    name: 'identificationintroduction',
    meta: { layout: 'default', auth: true },
    component: require('@/views/identification/IdentificationIntroduction').default,
  },
  {
    path: '/step',
    name: 'teststep',
    meta: { layout: 'default', auth: true, forceLandscape: true },
    component: require('@/views/steps/StepFrame').default,
  },
  {
    path: '/steps-flow',
    name: 'stepsFlow',
    meta: { layout: 'default' },
    component: require('@/components/DynamicSections').default,
  },
]
