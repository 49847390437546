import Vue from 'vue'
import mixpanel from 'mixpanel-browser'

// initializes default instance of Mixpanel client
mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY, {
  loaded: function() {
    console.log('Mixpanel loaded successfully')
  },
})

Vue.prototype.$mixpanel = mixpanel
