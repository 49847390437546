<template lang="pug">
  div

</template>

<script>
export default {
  props: {},

  data() {
    return {
      stepsData: stepsData,
    }
  },

  computed: {},

  watch: {},

  mounted() {
    console.log('DynamicSections.vue :31', this.stepsData)
  },

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
