import store from '@/store'
import CacheApi from '@/utils/cache-api'
import Bugsnag from '@bugsnag/js'

class StepBuilder {
  constructor() {
    this.cacheApi = new CacheApi()
    this.sectionCount = 0
    this.videoCount = 0
    this.currentVideoStep = 0
    this.callBack = null
  }

  async build(sections, callBack) {
    this.callBack = callBack

    store.commit('steps/SET_MEDIA_LOADED', false)
    return this.appendSections(sections)
  }

  async appendSections(sections) {
    let base = this.sectionCount

    // 12-13-21 temporarily stop caching videos due to an iOS 15+ issue with playing from cached video
    // this.buildMedia(sections)
    store.commit('steps/SET_MEDIA_LOADED', true)
    store.commit('steps/SET_MEDIA_LOADED_PERCENT', 100)

    if (base > 0) {
      // If there are existing sections, update the last section of the entry sections to
      // point to the next section
      store.dispatch('steps/updateSection', {
        sectionPos: base - 1,
        fields: {
          nextSectionPos: base,
        },
      })
    }

    this.sectionCount += sections.length

    return new Promise((resolve, reject) => {
      sections.forEach((section, offset) => {
        let index = base + offset
        let cb = function(event) {
          event.addMetadata('jsonData', {
            section,
            index,
          })
        }
        Bugsnag.addOnError(cb)
        var currentSection

        try {
          currentSection = this.buildCurrentSection(section, index)
          store.commit('steps/SET_SECTIONS', currentSection)
        } catch (error) {
          Bugsnag.notify(error)
        }

        Bugsnag.removeOnError(cb)

        if (index == 0) {
          // Check if section+step are in localstorage
          const savedSection = localStorage.getItem('currentSection')
          const savedStep = localStorage.getItem('currentStep')
          var parsedSection = null
          var parsedStep = null

          if (savedSection && savedStep) {
            // Pass custom bugsnag event metadata for errors inside this block
            let cb = function(event) {
              event.addMetadata('jsonData', {
                savedSection,
                savedStep,
              })
            }
            Bugsnag.addOnError(cb)

            try {
              parsedSection = JSON.parse(savedSection)
              parsedStep = JSON.parse(savedStep)
              store.commit('steps/SET_CURRENT_SECTION', parsedSection)
              store.commit('steps/SET_CURRENT_STEP', parsedStep)
            } catch (error) {
              Bugsnag.notify(error)
              store.commit('steps/SET_CURRENT_SECTION', currentSection)
              store.commit('steps/SET_CURRENT_STEP', currentSection.steps[0])
            }

            // Remove custom bugsnag event metadata before exiting the block
            Bugsnag.removeOnError(cb)
          } else {
            store.commit('steps/SET_CURRENT_SECTION', currentSection)
            store.commit('steps/SET_CURRENT_STEP', currentSection.steps[0])
          }
        }
      })

      resolve()
    })
  }

  buildCurrentSection(section, index) {
    const stepsCount = section.steps.length
    const nextSectionPos = this.sectionCount > index + 1 ? index + 1 : -1
    const steps = []

    section.steps.forEach((rawStep, stepIndex) => {
      const step = this.buildStep(rawStep, stepsCount, stepIndex)

      steps.push(step)
    })

    return {
      title: section.title,
      stepsCount: stepsCount,
      indexPos: index,
      nextSectionPos: nextSectionPos,
      steps: steps,
    }
  }

  buildSteps() {}

  buildStep(step, stepsCount, index) {
    const nextStepPos = stepsCount > index + 1 ? index + 1 : -1

    return {
      stepPos: index,
      nextStepPos: nextStepPos,
      ...step,
    }
  }

  async buildMedia(sections) {
    const videoSection = sections.find(s => s.title == 'Videos')
    if (videoSection) {
      this.videoCount = videoSection.steps[0].config.data.length

      await Promise.allSettled(
        videoSection.steps[0].config.data.map(async vid => {
          try {
            await this.cacheApi.addData(vid.videoUrl)
          } catch (error) {
            console.log('stepBuilder.js :95', 'Failed to cache video', error)
          }

          // cache timer videos, if any
          if (vid.actionButton?.timerVideoFile) {
            try {
              await this.cacheApi.addData(
                `${process.env.VUE_APP_MEDIA_ROOT_URL}${this.timerVideoFile}`
              )
            } catch (error) {
              console.log('stepBuilder.js :95', 'Failed to cache timer video', error)
            }
          }

          this.currentVideoStep++
          const percentLoaded = (this.currentVideoStep / this.videoCount) * 100
          const percent = +percentLoaded.toPrecision(1)

          store.commit('steps/SET_MEDIA_LOADED_PERCENT', percent)
        })
      )
    }

    if (this.videoCount == this.currentVideoStep) {
      store.commit('steps/SET_MEDIA_LOADED', true)
    }
  }
}

export { StepBuilder as default }
