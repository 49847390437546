import Vue from 'vue'
import store from '@/store'

const modal = new Vue({
  store,
  computed: {
    visible() {
      return !!this.$store.state.modal.modals.length
    },
  },

  methods: {
    close(componentPath) {
      this.$store.commit('modal/CLOSE', componentPath)
    },

    closeAll(componentPath) {
      this.$store.commit('modal/CLOSE_ALL')
    },

    open(componentPath, props = {}) {
      this.$store.commit('modal/OPEN', { componentPath: componentPath, props: props })
    },
  },
})

export default {
  install: function(Vue) {
    Vue.modal = modal
    Vue.prototype.$modal = modal
  },
}
