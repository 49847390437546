export const state = {
  current: null,
  orientation: null,
  isTouchDevice: false,
}

export const getters = {}

export const mutations = {
  SET_CURRENT(state, breakpoint) {
    state.current = breakpoint
  },

  SET_ORIENTATION(state, orientation) {
    state.orientation = orientation
  },

  SET_TOUCH(state, touch) {
    state.isTouchDevice = touch
  },
}

export const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
