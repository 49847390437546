import Vue from 'vue'
import dayjs from 'dayjs'

export const state = {
  medplumOrderId: null,
  barcodeId: null,
  kitType: null,
  sku: null,
  flowSections: null,
  configKeys: null,
  phone: null,
  isIOS15: null,
  proceededWithoutVideo: false,
}

export const getters = {
  getMedplumOrderId: state => {
    return state.medplumOrderId ? state.medplumOrderId : localStorage.getItem('medplumOrderId')
  },
  getBarcodeId: state => {
    return state.barcodeId ? state.barcodeId : localStorage.getItem('barcodeId')
  },
  getKitType: state => {
    return state.kitType ? state.kitType : localStorage.getItem('kitType')
  },
  getSku: state => {
    if (!state.sku) {
      state.sku = localStorage.getItem('sku')
    }
    return state.sku
  },
  getFlowSections: state => {
    return state.flowSections
      ? state.flowSections
      : JSON.parse(localStorage.getItem('flowSections'))
  },
  getConfigKeys: state => {
    return state.configKeys ? state.configKeys : JSON.parse(localStorage.getItem('configKeys'))
  },
  getDocusignStatus: state => {
    return state.docusignStatus
      ? state.docusignStatus
      : JSON.parse(localStorage.getItem('docusignStatus'))
  },
  getPhone: state => {
    return state.phone ? state.phone : localStorage.getItem('phone')
  },
  getIsIOS15: state => {
    if (state.isIOS15 == null) {
      state.isIOS15 = /iPhone/.test(navigator.userAgent) && /15_*/.test(navigator.userAgent)
    }
    return state.isIOS15
  },
  getVideoPermission: state => {
    return localStorage.getItem('proceededWithoutVideo') ? localStorage.getItem('proceededWithoutVideo') === 'true' : state.proceededWithoutVideo
  }
}

export const mutations = {
  SET_MEDPLUM_ORDER_ID(state, id) {
    state.medplumOrderId = id
    localStorage.setItem('medplumOrderId', id)
  },
  SET_BARCODE_ID(state, id) {
    state.barcodeId = id
    localStorage.setItem('barcodeId', id)
  },
  SET_KIT_TYPE(state, kitType) {
    state.kitType = kitType
    localStorage.setItem('kitType', kitType)
  },
  SET_FLOW_SECTIONS(state, flowSections) {
    state.flowSections = flowSections
    localStorage.setItem('flowSections', JSON.stringify(flowSections))
  },
  SET_CONFIG_KEYS(state, configKeys) {
    state.configKeys = configKeys
    localStorage.setItem('configKeys', JSON.stringify(configKeys))
  },
  SET_SKU(state, sku) {
    state.sku = sku
    localStorage.setItem('sku', sku)
  },
  SET_DOCUSIGN_STATUS(state, docusignStatus) {
    state.docusignStatus = docusignStatus
    localStorage.setItem('docusignStatus', JSON.stringify(docusignStatus))
  },
  SET_PHONE(state, phone) {
    phone = Buffer.from(phone, 'base64').toString().split(',')[0]
    if (phone.length >= 10) {
      state.phone = phone
      localStorage.setItem('phone', phone)
    }
  },
  SET_VIDEO_PERMISSION(state, permission) {
    state.proceededWithoutVideo = permission
    localStorage.setItem('proceededWithoutVideo', permission)
  },
}

export const actions = {
  async create({ commit, getters }, { userInfo }) {
    try {
      const response = await Vue.axios.post('/user-info', {
        orderId: getters.getMedplumOrderId,
        userInfo: userInfo,
      })

      // TODO: Check & Save response
      return response
    } catch (error) {
      console.log(error, error?.response)
      if (error.response.status == 404) return error.response.data
    }
  },

  async collectSample({ commit, getters }, data) {
    try {
      const date = dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
      const response = await Vue.axios.post('/specimen', {
        barcodeId: getters.getBarcodeId,
        specimenType: data.specimenType,
        specimenIndex: data.specimenIndex,
        collectedDateTime: date
      })
      return response
    } catch (error) {
      console.log(error, error?.response)
      if (error.response.status == 404) return error.response.data
    }
  },

  async upload({ commit, getters }, { file, fileName }) {
    try {
      let data = new FormData()
      const orderId = getters.getMedplumOrderId

      data.append('orderId', orderId)
      data.append('file', file, `${orderId}-${fileName}`)
      return await Vue.axios.post('/upload', data)
    } catch (error) {
      console.log(error, error?.response)
      if (error.response?.status == 404) return error.response.data
    }
  },

  async uploadSignature({ commit, getters }, { file, fileName }) {
    try {
      let data = new FormData()
      const orderId = getters.getMedplumOrderId

      data.append('orderId', orderId)
      data.append('file', file, `${orderId}-${fileName}`)
      return await Vue.axios.post('/signature', data)
    } catch (error) {
      console.log(error, error?.response)
      if (error.response?.status == 404) return error.response.data
    }
  },

  async completedKit({ commit, getters }) {
    try {
      let data = new FormData()
      const phone = getters.getPhone
      const orderId = getters.getMedplumOrderId

      data.append('orderId', orderId)
      data.append('phone', phone)
      return await Vue.axios.post('/completed-kit', data)
    } catch (error) {
      console.log(error, error?.response)
      if (error.response?.status == 404) return error.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
