import Vue from 'vue'
import store from '@/store'
import MQS from '@/utils/mediaQuerySensor'

const breakpoint = new Vue({
  store,
  computed: {
    current: {
      get: function() {
        return this.$store.state.breakpoints.current
      },

      set: function(breakpoint) {
        this.$store.commit('breakpoints/SET_CURRENT', breakpoint)
      },
    },
    orientation: {
      get: function() {
        return this.$store.state.breakpoints.orientation
      },

      set: function(orientation) {
        this.$store.commit('breakpoints/SET_ORIENTATION', orientation)
      },
    },
    isTouchDevice: {
      get: function() {
        return this.$store.state.breakpoints.isTouchDevice
      },

      set: function(touch) {
        this.$store.commit('breakpoints/SET_TOUCH', touch)
      },
    },
    isPhone: {
      get: function() {
        return ['phone-small', 'phone'].includes(this.current)
      },
    },
    isMobile: {
      get: function() {
        return this.current == 'mobile'
      },
    },
    isTablet: {
      get: function() {
        return this.current == 'tablet'
      },
    },
    isTouch: {
      get: function() {
        return ['phone-small', 'phone', 'mobile', 'tablet'].includes(this.current)
      },
    },
    isDesktop: {
      get: function() {
        return this.current == 'desktop'
      },
    },
    isWidescreen: {
      get: function() {
        return this.current == 'widescreen'
      },
    },
    isFullHD: {
      get: function() {
        return this.current == 'fullhd'
      },
    },
  },

  beforeDestroy() {
    MQS.empty()
  },

  methods: {
    build() {
      MQS.empty()

      MQS.add({
        ref: 'phone-small',
        mediaQuery: 'screen and (max-width: 320px)',
        action: () => (this.current = 'phone-small'),
      })

      MQS.add({
        ref: 'phone',
        mediaQuery: 'screen and (min-width: 321px) and (max-width: 480px)',
        action: () => (this.current = 'phone'),
      })

      MQS.add({
        ref: 'mobile',
        mediaQuery: 'screen and (min-width: 481px) and (max-width: 768px)',
        action: () => (this.current = 'mobile'),
      })

      MQS.add({
        ref: 'tablet',
        mediaQuery: 'screen and (min-width: 769px) and (max-width: 1023px)',
        action: () => (this.current = 'tablet'),
      })

      MQS.add({
        ref: 'desktop',
        mediaQuery: 'screen and (min-width: 1024px)',
        action: () => (this.current = 'desktop'),
      })

      MQS.add({
        ref: 'portrait',
        mediaQuery: 'screen and (max-aspect-ratio: 14/9)',
        action: () => (this.orientation = 'portrait'),
      })

      MQS.add({
        ref: 'landscape',
        mediaQuery: 'screen and (min-aspect-ratio: 14/9)',
        action: () => (this.orientation = 'landscape'),
      })

      MQS.add({
        ref: 'isTouch',
        mediaQuery: '(hover: none) and (pointer: coarse)',
        action: () => (this.isTouchDevice = true),
      })

      MQS.add({
        ref: 'isHover',
        mediaQuery: '(hover: hover)',
        action: () => (this.isTouchDevice = false),
      })

      // MQS.add({
      //   ref: 'widescreen',
      //   mediaQuery: 'screen and (min-width: 1215px) and (max-width: 1407px)', // Your Media Query
      //   action: () => (this.current = 'widescreen'),
      // })
    },
  },
})

export default {
  install: function(Vue) {
    Vue.breakpoint = breakpoint
    Vue.prototype.$breakpoint = breakpoint
  },
}
