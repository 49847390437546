import Vue from 'vue'

const MixpanelAPI = {
  track: (eventName, params) => {
    try {
      Vue.prototype.$mixpanel.track('Collection App - ' + eventName, params)
    } catch (e) {
      console.log(e)
    }
  },
}

export default MixpanelAPI
