function validate(binding) {
  if (typeof binding.value !== 'function') {
    console.warn('[Esc-key-listen:] provided expression', binding.expression, 'is not a function.')
    return false
  }

  return true
}

let elementsListening = []

export default {
  install(Vue) {
    Vue.directive('esc-close', {
      bind(el, binding, vnode) {
        if (!validate(binding)) return

        elementsListening.push(el)

        function handler(e) {
          if (e.key == 'Escape') {
            const topElementListening = elementsListening[elementsListening.length - 1]
            if (el == topElementListening) {
              elementsListening.pop()
              e.stopPropagation()
              binding.value.call()
            }
          }
        }

        el.__escKeyListen__ = { handler: handler }

        document.addEventListener('keyup', el.__escKeyListen__.handler, true)
      },

      unbind(el, binding, vnode) {
        document.removeEventListener('keyup', el.__escKeyListen__.handler, true)
        delete el.__escKeyListen__
      },
    })
  },
}
