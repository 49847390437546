import Vue from 'vue'

import ScrolledToEnd from '@/directives/scrolled-to-end'
Vue.use(ScrolledToEnd)

import ClickAway from '@/directives/clickaway'
Vue.directive('click-outside', ClickAway)

import Closeable from '@/directives/closeable'
Vue.use(Closeable)

import EscClose from '@/directives/escClose'
Vue.use(EscClose)

import Visability from '@/directives/visability'
Vue.use(Visability)

