export const state = {
  modals: [],
}

export const getters = {}

export const mutations = {
  OPEN(state, modal) {
    const index = state.modals.findIndex(m => m.componentPath == modal.componentPath)

    if (index == -1) state.modals.push(modal)
  },

  CLOSE(state, componentPath) {
    const index = state.modals.findIndex(m => m.componentPath == componentPath)

    if (index !== -1) state.modals.splice(index, 1)
  },

  CLOSE_ALL(state) {
    state.modals = []
  },
}

export const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
